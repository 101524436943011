@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: "Clash Display Variable";
  src: url("/font/ClashDisplay-Variable.ttf");
}

@font-face {
  font-family: "Consolas";
  src: url("/font/CONSOLA.TTF");
}

body {
  font-family: "Clash Display Variable";
}

iframe {
  pointer-events: all;
}

iframe.active {
  pointer-events: none;
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: clip;
}

@layer base {
  .center-x {
    @apply translate-x-[-50%] left-[50%];
  }

  .center-y {
    @apply translate-y-[-50%] top-[50%];
  }

  .center-x-y {
    @apply translate-x-[-50%] left-[50%] translate-y-[-50%] top-[50%];
  }

  .heading {
    @apply text-[42px] font-semibold leading-[119%] -968:text-[36px] -600:text-[24px];
  }
  .heading-para {
    @apply text-[20px] leading-[140%] -968:text-[16px] -600:text-[13px] tracking-wide;
  }

  .chat-bubble {
    @apply text-[15px] leading-[140%] p-[16px] rounded-[20px] -600:rounded-[15px] -600:text-[11px] -600:p-[12px];
  }

  .chat-message img {
    @apply -600:scale-[0.8];
  }

  .chat-message {
    @apply opacity-0 translate-y-[50px] flex gap-[9px] items-end -600:gap-[6px];
  }

  .btn-hover {
    @apply hover:!text-opacity-65 hover:translate-y-[-5px] duration-300;
  }

  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

.custom-scroll::-webkit-scrollbar {
  height: 3px;
  width: 4px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background: #7547dd;
  border-radius: 10px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
  background: #0b0615;
}

.nav-desktop.active,
.nav-mobile.active {
  background-color: #0b0615;
  border-bottom: 1px solid #ffffff1a;
}

.hero-gradient-text {
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 34%, #9b99a1 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.hero-span {
  -webkit-text-fill-color: initial;
}

.btn-left-conic-gradient {
  position: relative;
  background: linear-gradient(
    70deg,
    #ffffffbd 0%,
    #ffffff47 12%,
    #ffffff4d 43%,
    #ffffff0f 50%,
    #ffffff4d 70%,
    #ffffff9e 100%
  );
}

.cat-chat-gradient {
  background: linear-gradient(81deg, #5a3dcd, #f28686 150%);
}

.bot-chat-gradient {
  background: linear-gradient(76deg, #280e91, #480f6e 140%);
}

.video-gradient {
  background: linear-gradient(
    to right,
    #ffffff 0%,
    #7f56d9 38%,
    #ffffff00 100%
  );
}

.video-radial-gradient {
  background: radial-gradient(
    ellipse,
    #0b061500 0%,
    #0b061500 60%,
    #0b0615 100%
  );
}

.about-card-gradient-left {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
.about-card-gradient-right {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.research-gradient {
  background: linear-gradient(
    to bottom,
    #0b061500 0%,
    #0b061500 30%,
    #0b061500 49%,
    #0b061596 71%,
    #0b0615 100%
  );
}

/* .nav-link::before,
.footer-link::before, */
/* .blog-video-card .heading::before {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  bottom: 0;
  left: 0;
  background-color: white;
  height: 1px;
  transform-origin: left;
  transition: transform 0.5s cubic-bezier(0.76, -0.03, 0.36, 1.03);
} */

/* .nav-link:hover::before,
.footer-link:hover::before, */
/* .blog-video-card:hover .heading::before {
  transform: scaleX(1);
  transform-origin: right;
} */

.nav-link,
.footer-link {
  transition: 0.2s ease-in-out;
}
.nav-link:hover,
.footer-link:hover {
  color: rgba(255, 255, 255, 0.6);
}

.btn-arrow img {
  transition: 0.4s cubic-bezier(0.31, 0.51, 0.11, 1.54);
}

.btn-arrow:hover img {
  transform: rotate(45deg) scale(1);
}

.action-bubble,
.nav-btn {
  backdrop-filter: blur(35px);
}

.safety-bg-gradient {
  background: linear-gradient(
    to bottom,
    #0b061500 0%,
    #0b061500 30%,
    #0b061596 60%,
    #0b0615 100%
  );
}

strong {
  font-weight: inherit;
}

.blog-descrip {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.partner-text.active {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.spinner {
  animation: spin 0.6s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.word,
.heading-anim,
.para-anim,
.char {
  will-change: transform;
}

.heading-anim {
  overflow: hidden;
}

.word,
.char,
span {
  line-height: inherit;
}

.word {
  /* overflow: hidden; */
  /* line-height: inherit;
   */
  position: relative;
}

.chat-avatar {
  max-width: 36px;
}

@media (max-width: 600px) {
  .chat-avatar {
    max-width: 27px;
  }
}

.blog-video-card:hover .overlay {
  opacity: 1;
}

/* .blog-video-card .heading::before {
  background-color: #c7adff;
} */

.blog-video-card:hover .heading {
  color: #c7adff;
  text-decoration: underline;
}
.blog-video-card:hover h2 {
  color: #c7adff;
}
.blog-video-card:hover span {
  color: #c7adff;
}

@media (max-width: 1024px) {
  .blog-video-card:hover .heading {
    color: white;
    text-decoration: none;
  }
  .blog-video-card:hover h2 {
    color: white;
  }
  .blog-video-card:hover span {
    color: #ffffffb2;
  }

  .nav-link:hover,
  .footer-link:hover {
    color: rgba(255, 255, 255, 1);
  }
}
